
import { defineComponent, inject, reactive, ref, toRefs } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
import { CardListItemDataType } from './typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { queryFakeList } from '@/api/list/card-list';

export default defineComponent({
  name: 'CardList',
  setup() {
    const { t } = useI18n();
    const state = reactive({
      loading: false,
      list: [],
    });

    const nullData: Partial<CardListItemDataType> = {};

    const { context } = useFetchData(() => {
      return queryFakeList().then(res => {
        return {
          data: res,
          total: 100,
        };
      });
    });

    return {
      t,
      context,
      ...toRefs(state),
      nullData,
      isMobile: inject('isMobile', ref(false)),
    };
  },
  components: {
    PlusOutlined,
  },
});
